<template>
  <v-sheet style="position: relative">
    <v-overlay
      :value="dataLoading"
      absolute
      z-index="1"
    >
      <v-row class="d-flex">
        <v-progress-circular
          indeterminate
          size="64"
          style="max-height: 100vh"
        />
      </v-row>
    </v-overlay>
    <div v-if="products">
      <horizontal-product
        v-for="(item, index) in products"
        :key="'list'+index"
        :item="item"
      />
    </div>
  </v-sheet>
</template>

<script>
import HorizontalProduct from '@/components/Product/HorizontalProduct';

export default {
  name: 'ProductList',
  components: { HorizontalProduct },
  props: {
    products: {
      type: [Array, Object]
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    dataLoading: false,
  }),
  watch: {
    loading: {
      handler(value) {
        this.dataLoading = value;
      },
      immediate: true,
      deep: true
    }
  }

};
</script>

<style scoped></style>
