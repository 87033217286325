<template>
  <v-card class="product-card">
    <v-card-text class="d-flex">
      <v-row>
        <v-col
          class="d-flex justify-center justify-lg-start"
          cols="12"
          lg="3"
        >
          <router-link
            :title="item.full_name"
            :to="routeToProductDetails()"
          >
            <v-img
              :src="item?.thumbnail || fallbackImg"
              class="grey lighten-2 rounded"
              height="250"
              max-width="250"
              @click="setProduct(item)"
            >
              <template #placeholder>
                <v-row
                  align="center"
                  class="fill-height ma-0"
                  justify="center"
                >
                  <v-progress-circular
                    color="grey lighten-5"
                    indeterminate
                  />
                </v-row>
              </template>
            </v-img>
          </router-link>
        </v-col>

        <v-col
          cols="12"
          lg=""
        >
          <v-card-text class="pa-0 pl-lg-16">
            <router-link
              :title="item.name"
              :to="routeToProductDetails()"
            >
              <v-card-title
                class="font-weight-bold product-title px-0 pt-0 pb-0"
                @click="setProduct(item)"
              >
                {{ item.full_name }}
              </v-card-title>
            </router-link>

            <v-card-subtitle class="px-0 mt-1">
              <span
                v-for="(category, index) in item.product.categories"
                :key="'prodcat'+index"
              >
                {{ category.name }}
                <strong v-if="(index+1) < item.product.categories.length"> || </strong>
              </span>
              <br>

              Sold By :
              <router-link
                :to="`/vendor/${item.product.shop.slug}`"
                class="text-decoration-none primary--text text-capitalize"
              >
                {{ item.product.shop.name }}
              </router-link>
            </v-card-subtitle>

            <div class="product-content price">
              Price : {{ item.price | currency }} /Pc
            </div>
          </v-card-text>

          <v-card-actions class="justify-center justify-md-start pl-0 pl-lg-16 pt-5 ">
            <div class="">
              <v-btn
                block
                color="secondary"
                @click="handleAction(item, 'cart')"
              >
                <v-icon size="16">
                  mdi-cart
                </v-icon>
                Add To Cart
              </v-btn>
            </div>
          </v-card-actions>
        </v-col>
      </v-row>
      <div />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { storeHelper } from '@/utils/helper';
import { CART_ADD, cartNameSpace } from '@/store/modules/cart/types';
import fallbackImg from '@/assets/images/emptyShopLogo.jpg';

export default {
  name: 'HorizontalProduct',
  props: {
    item: {
      required: true,
      type: Object
    }
  },

  emits: ['action-clicked'],

  data() {
    return {
      fallbackImg,
    }
  },

  methods: {
    ...mapActions({
      addToCart: storeHelper(cartNameSpace, CART_ADD),
      setProduct: 'product/setProduct',
    }),

    handleAction(item, action) {
      let actionName;

      switch (action) {
        case 'view' :
          actionName = 'view';
          break;

        case 'compare' :
          actionName = 'compare';
          break;

        case 'wishlist' :
          actionName = 'wishlist';
          break;

        case 'cart' :
          actionName = 'cart';
          this.addToCart({
            item,
          });
          break;
      }
      this.$emit('action-clicked', { itemId: item, actionName });
    },
    routeToProductDetails() {
      return {
        name: 'ProductSingle',
        params: {
          variation : this.item.slug,
          product: this.item.product.slug,
          vendor: this.item.product.shop.slug
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>

.v-application a {
  color: #0d0c0cbd;
}
.product-content {

  &.price {
    font-weight: bold;
    font-size: 1rem;
  }

  &.count {
    font-size: 1rem;
  }

  &:first-of-type {
    margin-top: 16px;
  }

  &:last-of-type {
    //margin-bottom: 16px;
  }
}

.product-card {
  margin-bottom: 10px;

  .product-title {
    font-size: clamp(1.125rem, 0.247vw + 1.079rem, 1.375rem);
    line-height: clamp(1.688rem, 0.099vw + 1.669rem, 1.788rem);
  }
}
</style>
